const miniMenu = document.querySelector(`[data-custom-target='mini-menu']`)
const bigMenu = document.querySelector(`[data-custom-target='big-menu']`)
const bigMenuOpen = document.getElementById("big-menu")
const bigMenuClose = document.querySelector(`[data-custom-target-close='big-menu']`)

// document.querySelectorAll(".password-shower")?.forEach(item => {
//   item?.addEventListener("click", () => {
//     item?.firstElementChild?.classList.toggle("fa-eye-slash")
//     item?.previousElementSibling?.setAttribute("type", `${item?.previousElementSibling?.getAttribute("type") === "text" ? 'password' : 'text'}`)
//   })
// })

document.getElementById("mini-menu").addEventListener("click", function () {
  if (miniMenu.classList.contains("active")) {
    miniMenu.style.height = 0;
    miniMenu.style.opacity = 0;
    setTimeout(() => {
      miniMenu.style.display = "none";
      miniMenu.classList.remove("active");
      document.getElementById("main-body").classList.remove("active")
      document.querySelector("html").classList.remove("overflow-hidden")
    }, 250);
  } else {
    document.getElementById("main-body").classList.add("active")
    document.querySelector("html").classList.add("overflow-hidden")
    miniMenu.classList.add("active");
    miniMenu.style.display = "block";
    miniMenu.style.height = miniMenu.scrollHeight + "px";
    miniMenu.style.opacity = 1;
  }
})

bigMenuOpen.addEventListener("click", function () {
  this.classList.add("active")
  bigMenu.classList.add("active")
  document.getElementById("main-body").classList.add("active")
})

bigMenuClose.addEventListener("click", function () {
  bigMenu.classList.remove("active")
  bigMenuOpen.classList.remove("active")
  document.getElementById("main-body").classList.remove("active")
  document.querySelectorAll(".btn-menu-in").forEach((item, number, array) => {
    if (item.classList.contains("active")) {
      setTimeout(() => {
        item.click()
      }, 400)
    }
  })
})


const mql = window.matchMedia("(max-width: 992px)");


if (!mql.matches) {
  miniMenu.style.display = "flex"
  miniMenu.style.opacity = null
  miniMenu.style.height = null
  miniMenu.classList?.remove("active")
}

mql.onchange = (e) => {
  if (e.matches) {
    miniMenu.style.display = "none"
    miniMenu.style.opacity = 0
    miniMenu.style.height = 0
  } else {
    miniMenu.style.display = "flex"
    miniMenu.style.opacity = null
    miniMenu.style.height = null
    miniMenu.classList?.remove("active")
  }
};

window.addEventListener("click", (event) => {
  if (!event.target.closest("[data-custom-target='mini-menu']") && !event.target.closest("#mini-menu") && miniMenu.classList.contains("active")) {
    miniMenu.style.height = 0;
    miniMenu.style.opacity = 0;
    setTimeout(() => {
      miniMenu.style.display = "none";
      miniMenu.classList.remove("active");
      document.getElementById("main-body").classList.remove("active")
      document.querySelector("html").classList.remove("overflow-hidden")
    }, 250);
  }
  
  if (!event.target.closest("[data-custom-target='big-menu']") && !event.target.closest("#big-menu") && bigMenu.classList.contains("active")) {
    bigMenu.classList.remove("active")
    bigMenuOpen.classList.remove("active")
    document.getElementById("main-body").classList.remove("active")
    
    document.querySelectorAll(".btn-menu-in").forEach((item, number, array) => {
      if (item.classList.contains("active")) {
        setTimeout(() => {
          item.click()
        }, 400)
      }
    })
  }
})

window.addEventListener("resize", () => {
  if (mql.matches) {
    document.querySelectorAll(".btn-menu-in").forEach((item, number, array) => {
      if (item.classList.contains("active")) {
        item?.parentElement?.classList?.add("active")
        array.forEach(itm => !itm.parentElement.classList.contains("active") && itm?.parentElement?.classList?.add("remove"))
      }
    })
  } else {
    document.querySelectorAll(".btn-menu-in").forEach(item => item?.parentElement?.classList?.remove("remove"))
  }
})

document.querySelectorAll(".btn-menu-in").forEach((item, number, array) => {
  item.addEventListener("click", function () {
    item.classList.toggle("active")
    const srcChange = item.firstElementChild.firstElementChild.src.split(`smartphone${item.classList.contains("active") ? "" : "-active"}`)
    item.firstElementChild.firstElementChild.src = srcChange[0] + `smartphone-active` + srcChange[1];
    
    if (mql.matches) {
      item.parentElement.classList.toggle("active")
    }
    
    array.forEach(itm => {
      
      if (item.hash.substr(1) !== itm.hash.substr(1) && !mql.matches) {
        itm.classList.remove("active")
        itm.setAttribute("disabled", "")
        setTimeout(() => {
          itm.removeAttribute("disabled")
        }, 400)
      } else {
        if (item.hash.substr(1) !== itm.hash.substr(1) && mql.matches) {
          if (!itm.parentElement.classList.contains("active")) {
            itm.parentElement.classList.toggle("remove")
          }
        }
        
        
      }
      const targetID = document.querySelector(`#${itm.hash.substr(1)}`)
      
      if (targetID && targetID.classList.contains("show")) {
        targetID.classList.remove("show")
        targetID.classList.remove("collapse")
        targetID.classList.add("collapsing")
        setTimeout(() => {
          targetID.classList.remove("collapsing")
          targetID.classList.add("collapse")
        }, 400)
      }
      
      if (!itm.classList.contains("active")) {
        const srcChange = itm.firstElementChild.firstElementChild.src.split("smartphone-active")
        if (srcChange.length === 2) {
          itm.firstElementChild.firstElementChild.src = srcChange[0] + "smartphone" + srcChange[1]
        }
      }
      
    })
  })
})

document.querySelectorAll(".product-box").forEach((item, number, array) => item.addEventListener("click", () => {
  array.forEach(itm => itm.classList.remove("active"))
  item.classList.add("active")
}))


function classChange(e, name=null, activeClass = null) {
  e.classList.toggle(activeClass ?? 'bg-d3B15e');
  
  if (name) {
    if (e.classList.contains(activeClass ?? 'bg-d3B15e')) {
      const srcChange = e.firstElementChild.src.split(`${name}`);
      e.firstElementChild.src = srcChange[0] + `${name}-active` + srcChange[1];
    } else {
      const srcChange = e.firstElementChild.src.split(`${name}-active`);
      e.firstElementChild.src = srcChange[0] + `${name}` + srcChange[1];
    }
  }
}

function dropChange(e, name) {
  for (let i = 0; i < e.parentElement?.parentElement?.children?.length; i++) {
    e?.parentElement?.parentElement?.children?.[i].firstElementChild.classList.remove("active", "pe-none")
  }
  e.classList.add("active", "pe-none");
  document.getElementById(name).innerHTML = e.textContent;
}